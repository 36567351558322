export default {
    name: 'fulfillmentShipPackingPage',
    before: [],
    after: [],
    setup: (app) => {
        const pageName = 'fulfillmentShipPacking';
        const pageRoute = app.routes.getItem(pageName);
        if (pageRoute) {
            const routeProps = pageRoute.props;
            pageRoute.props = () => ({
                ...routeProps(),
                shipmentPrintPageRoute: '/shipment-print',
            });

            pageRoute.loader = () => import('./packages/CustomFulfillmentShipPacking/core');

            app.routes.changeItem(pageName, pageRoute);
        }
    },
};
